import { application } from 'services/core'

function AutoSuspension () {
  const elements = {}
  const initializeSelectors = () => {
    elements.startDate = document.getElementById('start_date_filter')
    elements.endDate = document.getElementById('end_date_filter')
  }

  const initializeListeners = () => {
    if (elements.startDate) { elements.startDate.addEventListener('change', pickDate, false) }
  }

  const pickDate = () => {
    elements.endDate.min = elements.startDate.value
  }

  return () => {
    initializeSelectors()
    initializeListeners()
  }
}

application.register(AutoSuspension, import.meta)

export default AutoSuspension
